import React, { useState } from 'react'

import Notification from './notification'
import NotificationsContext from './context'

const NotificationsProvider = ({ children }) => {
  const [state, setState] = useState({
    notifications: []
  })

  const removeNotification = (id) => {
    const notifications = state.notifications
    const removeIndex = notifications.findIndex(item => item.id === id)
    notifications.splice(removeIndex, 1)
    setState({
      ...state,
      notifications
    })
  }

  return (
      <NotificationsContext.Provider
        value={{
          notifications: state.notifications,
          addNotification: notification => {
            const id = '_' + Math.random().toString(36).substr(2, 9)
            setState({
              ...state,
              notifications: [
                ...state.notifications,
                {
                  id,
                  ...notification
                }
              ]
            })

            setTimeout(() => {
              removeNotification(id)
            }, 5000)
          }
        }}
      >
        <>
          {children}
          <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-6 space-y-4 pointer-events-none sm:p-6 sm:items-start flex-col z-50"
          >
            {state.notifications.map(notification => (
              <Notification key={notification.id} {...notification} removeNotification={removeNotification}/>
            ))}
          </div>
        </>
      </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
