exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-[id]-js": () => import("./../../../src/pages/categories/[id].js" /* webpackChunkName: "component---src-pages-categories-[id]-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-cookbook-posts-[id]-js": () => import("./../../../src/pages/cookbook-posts/[id].js" /* webpackChunkName: "component---src-pages-cookbook-posts-[id]-js" */),
  "component---src-pages-cookbook-posts-index-js": () => import("./../../../src/pages/cookbook-posts/index.js" /* webpackChunkName: "component---src-pages-cookbook-posts-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-media-[id]-js": () => import("./../../../src/pages/media/[id].js" /* webpackChunkName: "component---src-pages-media-[id]-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-pages-[id]-js": () => import("./../../../src/pages/pages/[id].js" /* webpackChunkName: "component---src-pages-pages-[id]-js" */),
  "component---src-pages-pages-index-js": () => import("./../../../src/pages/pages/index.js" /* webpackChunkName: "component---src-pages-pages-index-js" */),
  "component---src-pages-posts-[id]-js": () => import("./../../../src/pages/posts/[id].js" /* webpackChunkName: "component---src-pages-posts-[id]-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-recipes-[id]-js": () => import("./../../../src/pages/recipes/[id].js" /* webpackChunkName: "component---src-pages-recipes-[id]-js" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-series-[id]-js": () => import("./../../../src/pages/series/[id].js" /* webpackChunkName: "component---src-pages-series-[id]-js" */),
  "component---src-pages-series-index-js": () => import("./../../../src/pages/series/index.js" /* webpackChunkName: "component---src-pages-series-index-js" */)
}

