import React, { useContext } from 'react'

import NotificationsContext from './context'
import NotificationsProvider from './provider'

const Notifications = (props) => {
  return (
    <NotificationsProvider>
      <NotificationsComponent {...props}/>
    </NotificationsProvider>
  )
}

const NotificationsComponent = ({ children }) => {
  useContext(NotificationsContext)

  return (
    <>
      {children}
    </>
  )
}

export default Notifications
