import Helmet from 'react-helmet'
import React from 'react'

const TITLE = `Zucker&Jagdwurst`
export const isBrowser = typeof window !== "undefined"

const Browser = ({ title='Please add a title' }) => (
  <Helmet title={`CMS | ${title} | ${TITLE}`}/>
)

export const useTitle = (title) => {
  const newTitle = `CMS | ${title} | ${TITLE}`
  if (isBrowser) document.title = newTitle
  return newTitle
}

export default Browser
