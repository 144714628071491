// import "tailwindcss/dist/base.min.css"
import './src/styles/global.css'

import Apollo from './src/components/apollo'
import Notifications from './src/components/notifications'
import React from 'react'

export const wrapPageElement = ({ element, props }) => {
  return (
    <Apollo {...props}>
      <Notifications>
        {element}
      </Notifications>
    </Apollo>
  )
}
